import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./styles";

import fbIcon from "../../icons/fb.svg";
import instaIcon from "../../icons/insta_footer.svg";
import lnkdinIcon from "../../icons/lnkdin.svg";
import twitterIcon from "../../icons/twitter.svg";
import spotifyIcon from "../../icons/spotify.svg";
import youtubeIcon from "../../icons/youtube.svg";
import appleStore from "../../icons/apple_store.svg";
import googleStore from "../../icons/g_play.svg";
import cardIcon_One from "../../icons/pnp-footer.svg";
import cardIcon_Two from "../../icons/whistle-footer.svg";
import cardIcon_Three from "../../icons/bag-footer.svg";
import cardIcon_Four from "../../icons/footer-register.svg";

export default function DeskFooter() {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={clsx(classes.footerContainer)}>
          <div className={clsx(classes.textBox)}>
            <h2 className={clsx(classes.textBoxHead)}>
              Popular venues associated with us
            </h2>
            <p className={clsx(classes.textBoxPara)}>
              District Sports Complex | Fiton Sports | Olympus Arena | Garron
              Play Arena | The Playground | Sportz 360 | Kicksal | Soccerholic |
              District 5 | Adidas - The Base | Turf Enough
            </p>
          </div>
          <div className={clsx(classes.textBox)}>
            <h2 className={clsx(classes.textBoxHead)}>
              Academies partnered with us
            </h2>
            <p className={clsx(classes.textBoxPara)}>
              South Mumbai Chess Academy | Shailaja Gohad | Chanakya Chess Club
              | Hemant Shuttlers Academy | Badminton Academies In Mumbai |
              Cricket Academies in Mumbai | King's View Chess Academy | E7
              Fitness | The Centre For Capoeira India | Checkmate Chess Academy
            </p>
          </div>
          <div className={clsx(classes.textBox)}>
            <h2 className={clsx(classes.textBoxHead)}>Elite coaches with us</h2>
            <p className={clsx(classes.textBoxPara)}>
              George Lawrence | Mahendra Gokhale | Balachandran Manikkath |
              Shailaja Gohad | Janki Deole | Raj Shekhar | Ajinkya Keskar |
              Sriram Balaji | Siddharth Hazare | Vasudha Dawane | Sudhanshu
              Medsikar | Ganesh Bhilare | Satish Samant
            </p>
          </div>
          <div className={clsx(classes.textBox)}>
            <h2 className={clsx(classes.textBoxHead)}>Cities we are in</h2>
            <p className={clsx(classes.textBoxPara)}>
              <a
                href="https://www.khelomore.com/sports-venues/delhi-&-ncr"
                target="_blank"
              >
                Delhi NCR
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/kolkata"
                target="_blank"
              >
                Kolkata
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/mumbai"
                target="_blank"
              >
                Mumbai
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/bengaluru"
                target="_blank"
              >
                Bengaluru
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/pune"
                target="_blank"
              >
                Pune
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/hyderabad"
                target="_blank"
              >
                Hyderabad
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/chennai"
                target="_blank"
              >
                Chennai
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/kochi"
                target="_blank"
              >
                Kochi
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/ahmedabad"
                target="_blank"
              >
                Ahmedabad
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/chandigarh"
                target="_blank"
              >
                Chandigarh
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/nashik"
                target="_blank"
              >
                Nashik
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/coimbatore"
                target="_blank"
              >
                Coimbatore
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/vadodara"
                target="_blank"
              >
                Vadodara
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/nagpur"
                target="_blank"
              >
                Nagpur
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://www.khelomore.com/sports-venues/surat"
                target="_blank"
              >
                Surat
              </a>
            </p>
          </div>
          <hr className={clsx(classes.hrFooter)}></hr>
          {/* <div className={clsx(classes.flex,classes.footerCardsContainer,classes.spaceBw)}>
                    <div className={clsx(classes.footerCard, classes.flex,classes.alignCenter)}>
                        <a href="https://khelomore.com/sports-venues"> <img className={clsx(classes.footerCardIcon)} src={cardIcon_One} alt="icon" /></a>
                        <div className={clsx(classes.footerCardTextBox)}>
                            <a href="https://khelomore.com/sports-venues">
                                <h6 className={clsx(classes.footerCardHead)}>Book Sports Venues</h6>
                                <p className={clsx(classes.footerCardText)}>Choose from 1000+ sports venues</p>
                            </a>
                           
                        </div>
                    </div>
                    <div className={clsx(classes.footerCard, classes.flex,classes.alignCenter)}>
                        <a href="https://khelomore.com/sports-coaching/?category=at_home"> <img className={clsx(classes.footerCardIcon)} src={cardIcon_Two} alt="icon" /></a>
                        <div className={clsx(classes.footerCardTextBox)}>
                            <a href="https://khelomore.com/sports-coaching/?category=at_home">
                                <h6 className={clsx(classes.footerCardHead)}>Find a Coach Near You</h6>
                                <p className={clsx(classes.footerCardText)}>Get trained by coaches at home</p>
                            </a>
                        </div>
                    </div>
                    <div className={clsx(classes.footerCard, classes.flex,classes.footerCardMZero,classes.alignCenter)}>
                        <a href="https://shop.khelomore.com/"> <img className={clsx(classes.footerCardIcon)} src={cardIcon_Three} alt="icon" /></a>
                        <div className={clsx(classes.footerCardTextBox)}>
                            <a href="https://shop.khelomore.com/">
                                <h6 className={clsx(classes.footerCardHead)}>KM Shop</h6>
                                <p className={clsx(classes.footerCardText)}>Get sport essentials at best prices</p>
                            </a>  
                        </div>
                    </div>
                    <div className={clsx(classes.footerCard, classes.flex,classes.footerCardMZero,classes.alignCenter)}>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform"> <img className={clsx(classes.footerCardIcon)} src={cardIcon_Four} alt="icon" /></a>
                        <div className={clsx(classes.footerCardTextBox)}>
                          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform">
                            <h6 className={clsx(classes.footerCardHead)}>Register your Venue</h6>
                            <p className={clsx(classes.footerCardText)}>Get access to 1M+ users</p>
                          </a>                           
                        </div>
                    </div>
                </div>
                <hr className={clsx(classes.hrFooter)}></hr> */}
          <div
            className={clsx(classes.flex, classes.consumers, classes.spaceBw)}
          >
            <div
              className={clsx(
                classes.flex,
                classes.flex30,
                classes.spcaeEvenly,
                classes.borderRight
              )}
            >
              {/* <div className={clsx(classes.consumersCard)}>
                            <h3 className={clsx(classes.consumersHead)}>Categories</h3>
                            <span className={clsx(classes.consumersText)}>Sports Equipment</span>
                            <span className={clsx(classes.consumersText)}>Active Wear & Shoes</span>
                            <span className={clsx(classes.consumersText)}>Accessories & Essentials</span>
                            <span className={clsx(classes.consumersText)}>Food & Beverages</span>
                          </div> 
                          <div className={clsx(classes.consumersCard)}>
                              <h3 className={clsx(classes.consumersHead)}>For Consumers</h3>
                              <span className={clsx(classes.consumersText)}>Privacy Policy</span>
                              <Link to="/terms-conditions" className={clsx(classes.consumersText)}>Terms and Conditons</Link>
                              <Link to="/about-us" className={clsx(classes.consumersText)}>About Khelomore</Link>
                              <span className={clsx(classes.consumersText)}>FAQs</span>
                              <span className={clsx(classes.consumersText)}>Ask about Security</span>
                          </div> 
                          <div className={clsx(classes.consumersCard,classes.flex,classes.flexCol,classes.justifyCenter)}>
                              <span className={clsx(classes.consumersTextEnd)}>Raise a Request</span>
                              <span className={clsx(classes.consumersTextEnd)}>My Bookings</span>
                              <span className={clsx(classes.consumersTextEnd)}>Track Order</span>
                          </div> */}
              <div className={clsx(classes.consumersCard)}>
                <h3 className={clsx(classes.consumersHead)}>For Consumers</h3>
                <Link
                  to="/privacy-policy"
                  className={clsx(classes.consumersText)}
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-conditions"
                  className={clsx(classes.consumersText)}
                >
                  Terms & Conditons
                </Link>
                <Link to="/faq" className={clsx(classes.consumersText)}>
                  FAQs
                </Link>
                <Link to="/about-us" className={clsx(classes.consumersText)}>
                  About Khelomore
                </Link>
                <Link to="/careers" className={clsx(classes.consumersText)}>
                  Careers
                </Link>
                <Link
                  to="/partnerships"
                  className={clsx(classes.consumersText)}
                >
                  Partnerships
                </Link>
              </div>
              {/* <div className={clsx(classes.consumersCard)}>
                <h3 className={clsx(classes.consumersHead)}>Help & Support</h3>
                <a
                style={{
                  marginTop:"24px"
                }}
                  href="#"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  Raise a Request
                </a>
                <a
                  href="#"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  My Orders
                </a>
                <a
                  href="#"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  Track Order
                </a>
              </div> */}
            </div>

            <div
              className={clsx(
                classes.socialMediaContainer,
                classes.flex,
                classes.flexCol,
                classes.flex30,
                classes.spaceBw,
                classes.borderRight
              )}
            >
              {/* <div className={clsx(classes.socialMediaCard)}>
                              <div>
                                  <h5 className={clsx(classes.socialMediaHead)}>Follow us on</h5>
                                  <div className={clsx(classes.socialMediaIconCard, classes.flex)}>
                                      <div className={clsx(classes.socialMediaIcon,classes.flex, classes.justifyCenter)}>
                                        <a href="https://www.facebook.com/KheloMore/">
                                          <img  src={fbIcon} alt="icon" />
                                        </a>
                                      </div>
                                      <div className={clsx(classes.socialMediaIcon,classes.flex, classes.justifyCenter)}>
                                        <a href="https://www.instagram.com/khelomoreindia/">
                                          <img src={instaIcon} alt="icon" />
                                        </a>   
                                      </div>
                                      <div className={clsx(classes.socialMediaIcon,classes.flex, classes.justifyCenter)}>
                                        <a href="https://www.linkedin.com/company/khelomore/">
                                          <img src={lnkdinIcon} alt="icon" />
                                        </a>
                                      </div>
                                      <div className={clsx(classes.socialMediaIcon,classes.flex, classes.justifyCenter)}>
                                        <a href="https://twitter.com/KheloMore">
                                          <img className={classes.socialIcon} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/xIcon.png" alt="icon" />
                                        </a>
                                      </div>
                                      <div className={clsx(classes.socialMediaIcon)}>
                                        <a href="https://open.spotify.com/show/2jdNwHmsjEQM7SjG2NDvFP?si=1abf50a9f37440c4">
                                          <img src={spotifyIcon} alt="icon" />
                                        </a>
                                      </div>
                                      <div className={clsx(classes.socialMediaIcon,classes.flex, classes.justifyCenter)}>
                                        <a href="https://www.youtube.com/channel/UC9SRqiqrQ1v9RoNX-Lnmqbg">
                                          <img src={youtubeIcon} alt="icon" />
                                        </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={clsx(classes.downloadBox)}>
                              <h5 className={clsx(classes.downloadText)}>Download the app</h5>
                              <div>
                                  <a href="https://apps.apple.com/in/app/khelomore-book-sports-venues/id1536558207"> <img className={clsx(classes.appleStoreFooter)} src={appleStore} alt="appleStore" /></a>
                                  <a href="https://play.google.com/store/apps/details?id=com.khelomore.pnp&hl=en_IN&gl=US"> <img src={googleStore} alt="googleStore" /> </a>
                              </div>
                          </div> */}
              <div className={clsx(classes.consumersCard)}>
                <h3 className={clsx(classes.consumersHead)}>Categories</h3>
                <a
                  href="https://shop.khelomore.com/collections/sport-equipments"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  Sports Equipment
                </a>
                <a
                  href="https://shop.khelomore.com/collections/active-wear-and-shoes"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  Active Wear & Shoes
                </a>
                <a
                  href="https://shop.khelomore.com/collections/acessories-and-essentials"
                  target="_blank"
                  className={clsx(classes.consumersText)}
                >
                  Accessories & Essentials
                </a>
              </div>
            </div>

            <div className={clsx(classes.flex, classes.flex30)}>
              <div className={clsx(classes.contactSection)}>
                <h6 className={clsx(classes.contactHead)}>Contact Us</h6>
                <div className={clsx(classes.contactText)}>
                  <p
                    className={clsx(
                      classes.contactPara,
                      classes.contactPadding
                    )}
                  >
                    2nd Floor, KHIL House, Nehru Road, Vile Parle (East),
                    <br></br>Adjoining Orchid Hotel, Mumbai, Maharashtra 400099{" "}
                  </p>
                  <div>
                    <span className={(classes.contactNumber)}>+91 98 1989 0321</span> 
                    <span className={clsx(classes.contactPara)}>
                      info@khelomore.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className={clsx(classes.hrFooter)}></hr>
        </div>
        <footer
          className={clsx(
            classes.copyright,
            classes.flex,
            classes.justifyCenter,
            classes.alignCenter
          )}
        >
          <small className={clsx(classes.copyrightText)}>
            © 2025 Khelomore Sports Private Limited. All Rights Reserved.
          </small>
        </footer>
      </div>
    </>
  );
}
